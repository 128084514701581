import web3 from '../utils/Web3View'
import callWeb3 from '../utils/Web3'
import Hool from '../contract/Hool'

const instance = new callWeb3.eth.Contract(
	Hool.abi,
    Hool.address,
	)


export default instance