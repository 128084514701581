import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import AnimatedNumber from 'react-animated-number';
import './styles.css';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const GameRow = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  //  reveal-from-bottom
  const outerClasses = classNames(
    'total section center-content-mobile',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'total-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'total-split'
  );  

const [mul, setMul] = useState(0);

function currencyFormat(num) {
     return '฿' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

function getValue(d)  {
    if (d !== "") {
      let v = Number(d);
      return (
        <AnimatedNumber value={v} duration={300} formatValue={(n) => currencyFormat(n)} />
      )
    }
  }

 function calculateMul(s,p) {

    let newMul = p / s;
    // console.log(newMul);
    if (newMul > mul) {
      let t = newMul;
      setMul(t);
    }
    return <span>{mul.toFixed(1)}x</span>
  }

const imgSrc = `/game/` + props.id + `.png`

  if (props.active == '0') 
    return (
        <section
          {...props}
          className={outerClasses}
        >
          <div className="container">
            <div className={innerClasses} >
              <div className="row">

                <img
                    src={imgSrc}
                    loading="lazy"
                    alt=""
                    className="image-container"
                  />

                <div className="game-name">
                  {props.name}
                </div>

                <div className="comingsoon">
                  COMMING SOON
                </div>

              </div>
            </div>
          </div>
        </section>

      );

  return (
    
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses} >
          <div className="row">

            <img
                src={imgSrc}
                loading="lazy"
                alt=""
                className="image-container"
              />

            <div className="game-name">
              {props.name}
              <span className="badge">{calculateMul(props.pricestart, props.price)}</span>
            </div>

            <div className="tvl">
              <span className="text-header">Price</span>
              <div className="text-value">{getValue(props.price)}</div>
            </div>

            <div className="tvl">
              <span className="text-header">Total Value Locked</span>
              <div className="text-value">{getValue(props.tvl)}{props.tvlunit}</div>
            </div>

            <div className="ttv">
              <span className="text-header">Total Trading Volume</span> 
              <div className="text-value volume">{getValue(props.ttv)}{props.ttvunit}</div>
            </div>

            <div className="mkcap">
              <span className="text-header">Market Cap</span>
              <div className="text-value marketcap">{getValue(props.mkcap)}{props.mkcapunit}</div>
            </div>

            <div className="mkcap">
              <div style={{display: "flex", flexDirection: "column"}}>
                  <Button tag="a" width="60" color="primary" style={{padding: "2px 2px", height:"30px"}} target="_blank" href={props.url}>Play Now</Button>
                  <div style={{height:"10px", width:"10px"}} />
                  <Button tag="a" color="primary" style={{padding: "2px 2px", height:"30px"}} href="https://exchange.diamon.finance">Buy Token</Button>
                </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

GameRow.propTypes = propTypes;
GameRow.defaultProps = defaultProps;

export default GameRow;

