import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import AnimatedNumber from 'react-animated-number';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Total = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  //  reveal-from-bottom
  const outerClasses = classNames(
    'total section center-content-mobile',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'total-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'total-split'
  );  

  const [data, setData] = useState("");

  const fetchData = async () => {
    // console.log("Fetch Data");
    let api = 'https://api.diamon.finance/TVL5'
    // let api = 'http://localhost:8888/temp/diamon/game-data.php'
    try {
      const response = await fetch(api)
      const res = await response.json()
      // console.log(res);
      if (res != '') {
        if (res.total && res.total.tvl) {
          res.total.tvl.value = parseFloat(res.total.tvl.value) + 1146.6 // 1,146.6 M
          setData(res.total)
        }
      }

    } catch (error) {
      console.error('Unable to fetch price data:', error)
    }
  }


  // useEffect(() => {
    
    // let api = 'http://localhost:8888/temp/diamon/data.php'
    // const fetchData = async () => {
    //   try {
    //     const response = await fetch(api)
    //     const res = await response.json()
    //     // console.log(res.data.tvl.value);
    //     setData(res.data)

    //   } catch (error) {
    //     console.error('Unable to fetch price data:', error)
    //   }
    // }

  //   fetchData()
  // }, [setData]);

  useEffect(() => {
    fetchData()
    const interval = setInterval(() => { 
        fetchData()
      }, 10000);
    // console.log("Interval");
    return () => {
      console.log("Clear TOtal Interval");
      clearInterval(interval);
      // window.location.reload(false);
    };
  }, []);

  function currencyFormat(num) {
     return '฿' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  function getValue(d)  {
      if (d !== "") {
        let v = Number(d);
        return (
          <AnimatedNumber value={v} duration={300} formatValue={(n) => currencyFormat(n)} />
        )
      }
    }

  return (

    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="total-grid" style={{ width: "100%", backgroundColor: "#192230", borderRadius: "8px" }}>
            <div style={{ display: "flex", width: "100%",textAlign: "center" }}>
              <div className="total-grid-1">
                <div className="fw-300 total-grid-1" style={{ width: "100%",textAlign: "center" }}>Total Value Locked</div>
                <div className="total-grid-1" style={{ width: "100%",textAlign: "center" }}>
                  <span className="text-color-primary h1 fw-700">
                    {data !== "" && getValue(data.tvl.value)}{data !== "" && data.tvl.unit}
                    {data === "" && "0"}
                  </span>
                </div>    
              </div>
            </div>
            <div style={{ display: "flex", width: "100%", backgroundImage: "linear-gradient(to right, #00afff 0%, #006eff 100%)", borderRadius: "8px" }}>
              <div style={{ width: "100%" }}>
                <div className="fw-300" style={{ width: "100%", textAlign: "center" }} >Total Trading Volume</div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <span className="text-color-white h1 fw-700">
                    {data !== "" && getValue(data.volume.value)}{data !== "" && data.volume.unit}
                    {data === "" && "0"}
                  </span>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div className="total-grid-3">
                <div className="fw-300 total-grid-3" style={{ width: "100%",textAlign: "center" }} >Total NFT Volume</div>
                <div className="total-grid-3" style={{ width: "100%",textAlign: "center" }}>
                  <span className="text-color-primary h1 fw-700">
                    {data !== "" && getValue(data.nft.value)}{data !== "" && data.nft.unit}
                    {data === "" && "0"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Total.propTypes = propTypes;
Total.defaultProps = defaultProps;

export default Total;