import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import CoinBar from '../components/sections/CoinBar';
import Total from '../components/sections/Total';
// import MorningMoon from '../components/sections/MorningMoon';
import MetaMask from '../components/sections/MetaMask';

import GameData from '../components/sections/GameData';



// import '../components/sections/styles.css';


const Home = () => {

  return (
    <>
      <Hero className="" /> {/*illustration-section-01*/}
      <CoinBar />
      <Total />
      <MetaMask />
      <GameData />
      
    </>
  );
}

export default Home;

// <GameTable />
//       <GameRowHeader />
//       <GameRow id="mmv" name="MorningMoon Vilage" active='1' price="0.43" tvl="100M" ttv="200M" mkcap="150M"/>
//       <GameRow id="dk" name="Dicekingdom" active='1' tvl="130M" price="0.00" ttv="220M" mkcap="152M"/>

// <GameData />