import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const CoinBar = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'coinbar section center-content-mobile',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'coinbar-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'coinbar-split'
  );  

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses} center-content
        >
          <div style={{ width: "75%", margin: "0 auto", textAlign: "center", color: "white", background: "linear-gradient(180deg, rgba(0,0,0,0) calc(50% - 1px), rgba(255,255,255,0.9) calc(50%), rgba(0,0,0,0) calc(50% + 1px))" }}>
            <img
              src={require('./../../assets/images/lumi.png')}
              loading="lazy"
              style={{ display: "inline", height: "80px", margin: "20px" }}
              alt=""
            />
            <img
              src={require('./../../assets/images/kubcoin.png')}
              loading="lazy"
              style={{ display: "inline", height: "80px", margin: "20px" }}
              alt=""
            />
            <img
              src={require('./../../assets/images/bitcoin.svg')}
              loading="lazy"
              style={{ display: "inline", height: "80px", margin: "20px" }}
              alt=""
            />
            <img
              src={require('./../../assets/images/ethereum.png')}
              loading="lazy"
              style={{ display: "inline", height: "80px", margin: "20px" }}
              alt=""
            />
            <img
              src={require('./../../assets/images/tether.svg')}
              loading="lazy"
              style={{ display: "inline", height: "80px", margin: "20px" }}
              alt=""
            />
            <img
              src={require('./../../assets/images/usdcoin.svg')}
              loading="lazy"
              style={{ display: "inline", height: "80px", margin: "20px" }}
              alt=""
            />

          </div>

        </div>
      </div>
    </section>
  );
}

CoinBar.propTypes = propTypes;
CoinBar.defaultProps = defaultProps;

export default CoinBar;