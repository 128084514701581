import React from 'react';
// import sections
// import EventTop from '../components/sections/EventTop';
// import EventBurn from '../components/sections/EventBurn';
import EventHooligan from '../components/sections/EventHooligan';

const Event = () => {

  return (
    <>
      <EventHooligan/>
    </>
  );
}

export default Event;