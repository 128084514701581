import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import GameRow from './GameRow';
import GameRowEmp from './GameRowEmp';
import DefiRowEmp from './DefiRowEmp';
import DefiRow from './DefiRow';

import Web3 from 'web3';

import DKUtil from '../../utils/DKUtil'
import KUSDTUtil from '../../utils/KUSDTUtil'

import abiAuction from './abiauction.json';
import abiSold from './abisoldnft.json';

import './styles-game.css';

const GameCard = ({
  ...props
}) => {

const [data, setData] = useState([]);
const [total, setTotal] = useState("");
const [nftsum, setNFTSum] = useState(0);
// const [defi, setDefi] = useState(0);


const [dkPrice, setDKPrice] = useState(0);
const [dkAmount, setDKAmount] = useState(0);
const [kusdtAmount, setKusdtAmount] = useState(0);

  const fetchData = async () => {
    //==== TOTAL 
    // let api = 'https://api.diamon.finance/TVL5'
    // try {
    //   const response = await fetch(api)
    //   const res = await response.json()
    //   // console.log(res.data.tvl.value);
    //   // setData(res.data)
    //   setTotal(res.total)

    // } catch (error) {
    //   console.error('Unable to fetch price data:', error)
    // }

    //==== GAME 
    let gameapi = "https://api.diamon.finance/TVL5"
    try {
      const response = await fetch(gameapi)
      const res = await response.json()
      // console.log(res.data[0].price);
      if (res != '') {
        if (res.data)
          setData(res.data)
        if (res.total)
        setTotal(res.total)
      }
    } catch (error) {
      console.error('Unable to fetch price data:', error)
    }

    //--- DEFI PROJECT
    // let api_defi = 'https://api.diamon.finance/TVL4'
    // try {
    //   const response_defi = await fetch(api_defi)
    //   const res_defi = await response_defi.json()

    //   setDefi(res_defi.data.price)
    //   // console.log(res_defi.data.price)

    // } catch (error) {
    //   console.error('Unable to fetch price data:', error)
    // }

  }

const auctionValue = async () => {
  const web3Provider = new Web3.providers.HttpProvider('https://rpc.bitkubchain.io');
  const web3 = new Web3(web3Provider);
  // const web3 = new Web3(window.web3.currentProvider);
  const address = "0x2599C1d23C0AF6D14B141e8FDbbEd13e81E61427";
  const a = new web3.eth.Contract(abiAuction, address);
  const b = await a.methods.allAuctionEndedCount().call();


  const address2 = "0x874987257374cAE9E620988FdbEEa2bBBf757cA9";
  const c = new web3.eth.Contract(abiSold, address2);
  const d = await c.methods.allSoldListingCount().call();


  let s = Number(b) + Number(d);
  setNFTSum(s);

};

const callDKPrice = async () => {
  const DiamonLP = "0x9F718A7649d4a3423e56B677dc1Ee9955127a29e";
  const dk = DKUtil.methods.balanceOf(DiamonLP).call()
  dk.then(function(data) {
    // console.log("DK AMount : ", data)
    setDKAmount(data)
    if (kusdtAmount > 0)  {
      setDKPrice((kusdtAmount/data))
    }
  })

const kusdt = KUSDTUtil.methods.balanceOf(DiamonLP).call()
  kusdt.then(function(data) {
    // console.log("kusdt AMount : ", data)
    setKusdtAmount(data)
    if (dkAmount > 0)  {
      setDKPrice((data/dkAmount))
    }
  })
}


// useEffect(() => {
//   auctionValue()
//   fetchData()
// }, [setData]);

useEffect ( () => {
  setDKPrice( (kusdtAmount/dkAmount) * 34 )
}, [dkAmount, kusdtAmount])

useEffect(() => {
  fetchData()
  const interval = setInterval(() => { 
    fetchData()
    auctionValue()
  }, 10000);
  // console.log("Interval");
  return () => {
    console.log("Clear Card Interval");
    clearInterval(interval);
    // window.location.reload(false);
  };
}, []);

function amountFormat(num) {
  return  num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function calculateMulDefi(p) {

  let newMul = p / 27;
  return newMul.toFixed(2)
}

function priceStart(code) {
  let p = 1
  if (code == 'MMV') 
    p = 1.5
  else if (code == 'DK') {
    p = 13.5
  }else if (code == 'FAN') {
    p = 0.1
  }


  return p;
}

// function calculateDKMul(p) {
//   let newMul = p / 13.5;
//   return <span>{newMul.toFixed(1)}x</span>
// }

return (
      <>
      { data!=="" && data.map((row, index)=>  (
        row.active === "1" &&
        row.type === 'GameFi' &&
        <GameRow key={index} id={row.code} name={row.name} active={row.active} price={row.price} pricestart={priceStart(row.code)} tvl={row.tvl} tvlunit={row.tvlunit} ttv={row.ttv} ttvunit={row.ttvunit} mkcap={row.mkcap} mkcapunit={row.mkcapunit} url={row.url}/>
      ))}
      {/* <GameRow id="DK" name="Dice Kingdom" active={1} price={dkPrice} pricestart={13.5} tvl={0.00} tvlunit={'฿'} ttv={0.00} ttvunit={'฿'} mkcap={0.00} mkcapunit={'฿'} url={"https://dicekingdom.com"}/> */}
      {/* <GameRowEmp id="DK" name="Dice Kingdom" text1="January 2022" url="https://dicekingdom.com" /> */}
      {/* <GameRowEmp id="FAN" name="Fan Dungeon" text1="Coming soon" url="https://fansdungeon.com/"/> */}
      {/* <GameRowEmp id="SHEEP" name="Unsleep Sheep" text1="Available on testnet" url="https://unsleepsheepracing.com/" /> */}
      <GameRowEmp id="dms" name="Bit Monster NFT" text1="Coming soon" url="https://www.bitmonsternft.com/"/>
      <GameRowEmp id="ECQ" name="Eclipse Quest" text1="Coming soon" url="https://www.eclipsequest.bugbliostudio.com"/>
      <GameRowEmp id="XRB" name="XRB Galaxy Metaverse" text1="Coming soon" url="https://xrbgalaxy.com/"/>

      { total!=="" &&
      <DefiRowEmp id="megaland" name="Megaland" text1="Total NFT Volume" mul='' text2={"฿" + total.nft.value + total.nft.unit} 
      text3="Total NFTs Sold" text4={amountFormat(nftsum) + " items"} url="https://megaland.io"/>
      }

    { data && data.map((row, index)=>  (
        row.active === "1" &&
        row.type === 'Defi' &&
        <DefiRow key={index} id={row.code} name={row.name} active={row.active} price={18.12} pricestart={1.5} tvl={59.62} tvlunit={'M'} ttv={row.ttv} ttvunit={row.ttvunit} mkcap={181.22} mkcapunit={'M'} url={row.url}/>
    ))}
    { 2==1 && data && data.map((row, index)=>  (
        row.active === "1" &&
        row.type === 'Defi' &&
        <DefiRow key={index} id={row.code} name={row.name} active={row.active} price={row.price} pricestart={1.5} tvl={row.tvl} tvlunit={row.tvlunit} ttv={row.ttv} ttvunit={row.ttvunit} mkcap={row.mkcap} mkcapunit={row.mkcapunit} url={row.url}/>
    ))}
            {/* <DefiRowEmp id="yuemmai" name="Yuemmai" text1="Price" mul={calculateMulDefi(defi)} text2={"฿" + defi} url="https://www.xn--y3cabc8e7a4j.digital/"/>       */}
      <DefiRowEmp id="loremboard" name="Loremboard" text1="Available Now" mul='' url="https://loremboard.finance/"/>
      <DefiRowEmp id="freecity" name="Free City" text1="Available Now" mul='' url="https://freecity.finance/"/>
      <DefiRowEmp id="bitpower" name="Bitpower" text1="Available on testnet" mul='' url="https://ckt8712taz3f8giy.web.app/"/>
      <DefiRowEmp id="Lorem" name="KDEX" text1="Coming Soon" mul='' url="https://kdex.finance/"/>
      <DefiRowEmp id="pawnshop" name="Pawn Shop" text1="Available on testnet" mul='' url="https://pawns-finance.web.app/"/>
      
      </>
  );
}


export default GameCard;
