import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';

async function addrpc() {
  console.log("Add KUB");
    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x13881' }], // Hexadecimal version of 80001, prefixed with 0x
        });
    } catch (error) {
       console.log(error);
        if (error.code === 4902) {
          console.log("Try to add BITKUB");
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{ 
                        chainId: '0x60', // Hexadecimal version of 80001, prefixed with 0x
                        chainName: "Bitkub Chain",
                        nativeCurrency: {
                            name: "KUB",
                            symbol: "KUB",
                            decimals: 18,
                        },
                        rpcUrls: ["https://rpc.bitkubchain.io"],
                        blockExplorerUrls: ["https://bkcscan.com"],
                        iconUrls: [""],
                
                    }],
                });
            } catch (addError){
                console.log('Did not add network');
            }
        }
    }
}

async function addLumi() {
  const tokenAddress = '0x95013Dcb6A561e6C003AED9C43Fb8B64008aA361';
  const tokenSymbol = 'LUMI';
  const tokenDecimals = 18;
  const tokenImage = 'https://exchange.diamon.finance/images/coins/0x95013Dcb6A561e6C003AED9C43Fb8B64008aA361.png';

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log('Thanks for your interest!');
    } else {
      console.log('Your loss!');
    }
  } catch (error) {
    console.log(error);
  }
}

async function addKKub() {
  const tokenAddress = '0x67eBD850304c70d983B2d1b93ea79c7CD6c3F6b5';
  const tokenSymbol = 'KKUB';
  const tokenDecimals = 18;
  const tokenImage = 'https://exchange.diamon.finance/images/coins/kub.png';

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log('Thanks for your interest!');
    } else {
      console.log('Your loss!');
    }
  } catch (error) {
    console.log(error);
  }
}

async function addKUSDT() {
  const tokenAddress = '0x7d984C24d2499D840eB3b7016077164e15E5faA6';
  const tokenSymbol = 'KUSDT';
  const tokenDecimals = 18;
  const tokenImage = 'https://exchange.diamon.finance/images/coins/0x7d984C24d2499D840eB3b7016077164e15E5faA6.png';

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log('Thanks for your interest!');
    } else {
      console.log('Your loss!');
    }
  } catch (error) {
    console.log(error);
  }
}

async function addLorem() {
  const tokenAddress = '0x42A7ECC6D2D2780C2C2daAa8F7f2d2C2557e276b';
  const tokenSymbol = 'Lorem';
  const tokenDecimals = 18;
  const tokenImage = 'https://diamon.finance/defi/lorem-token.png';

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log('Thanks for your interest!');
    } else {
      console.log('Your loss!');
    }
  } catch (error) {
    console.log(error);
  }
}

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const MetaMask = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className="hero-content">
            <div className="container-sm">
              <p className="m-0 mb-32" data-reveal-delay="400">
                Add token to MetaMask 
                
              </p>
              <div>

                <img
                  src={require('./../../assets/images/bitkub-chain.png')}
                  loading="lazy"
                  style={{ display: "inline", height: "50px", margin: "20px", cursor: "pointer" }}
                  alt=""
                  onClick={addrpc}
                />

                <img
                  src={require('./../../assets/images/lumi.png')}
                  loading="lazy"
                  style={{ display: "inline", height: "60px", margin: "20px", cursor: "pointer" }}
                  alt=""
                  onClick={addLumi}
                />

                <img
                  src={require('./../../assets/images/kubcoin.png')}
                  loading="lazy"
                  style={{ display: "inline", height: "60px", margin: "20px", cursor: "pointer"}}
                  alt=""
                  onClick={addKKub}
                />

                <img
                  src={require('./../../assets/images/tether.png')}
                  loading="lazy"
                  style={{ display: "inline", height: "60px", margin: "20px", cursor: "pointer"}}
                  alt=""
                  onClick={addKUSDT}
                />

                <img
                  src={require('../../assets/images/lorem.png')}
                  loading="lazy"
                  style={{ display: "inline", height: "64px", margin: "20px", cursor: "pointer"}}
                  alt=""
                  onClick={addLorem}
                />


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

MetaMask.propTypes = propTypes;
MetaMask.defaultProps = defaultProps;

export default MetaMask;
