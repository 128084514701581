import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import GameCard from './GameCard';
import GameTable from './GameTable';

import './styles-game.css';

const GameData = ({
  ...props
}) => {


return (
    <div>
      <div className="game-table">
        <GameTable />
      </div>
      <div className="game-card">
        <GameCard />
      </div>
    </div>
    
  );
}


export default GameData;

// <div className="game-card">
//         <GameRow id="mmv" name="MorningMoon Vilage" active='1' price="0.43" tvl="100M" ttv="200M" mkcap="150M" />
//         <GameRow id="dk" name="Dicekingdom" active='0' tvl="130M" price="0.00" ttv="220M" mkcap="152M"/>
//       </div>