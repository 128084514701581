import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import PropTypes from 'prop-types';

import PunkUtil from '../../utils/PunkUtil'
import KKUBUtil from '../../utils/KKubUtil'
import PunkGUtil from '../../utils/PunkGUtil'

import HoolUtil from '../../utils/HoolUtil'

import './styles.css';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}


const EventBurn = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false)

  const [allowPunkAmount, setAllowPunkAmount] = useState(0)
  const [allowKKUBAmount, setAllowKKUBAmount] = useState(0)

  const [punkAmount, setPunkAmount] = useState(0)
  const [kkubAmount, setKKUBAmount] = useState(0)

  const [canClaim, setCanClaim] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const [openRedeem, setOpenRedeem] = useState(false)

  const [message, setMessage] = useState('Waiting for processing ... ')

  const diamonRouter = "0xd5f7ca46A009F354154Ba356e00B663a35A41DB9";//"0x6953A1cb26Ce35dc921D243a915D6d3E78793A49"


  let currentAccount = '';

  function handleAccountsChanged(accounts) {

    // console.log("handleAccountsChanged ", accounts);
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      console.log('Please connect to MetaMask.');
      currentAccount = '';
    } else if (accounts[0] !== currentAccount) {
      currentAccount = accounts[0];
      // Do any other work!
    }

    setAddress(currentAccount);
  }

  function calltest() {
    // const r = await PunkUtil.methods.balanceOf("0x56273E0722CBB6F213846e8849766445916E6Ff8").call()

    // const r = await PunkUtil.methods.approve("0x56273E0722CBB6F213846e8849766445916E6Ff8", 10).send({
    //   from: "0x56273E0722CBB6F213846e8849766445916E6Ff8",
    //   gas: "100000"
    // })
    // console.log(r)

    // checkApprovePunk()

    // 0xAb30a29168D792c5e6a54E4bcF1Aec926a3b20FA

    console.log("TEST");
    setShowModal(!showModal)
  }

  function openModal() {
    setShowModal(true)
  }

  function closeModal() {
    // console.log("CLOSER")
    setShowModal(false)
    setMessage("Waiting for processing ... ")
  }

  async function checkAll() {

    checkOpenRedeem()

    checkApprovePunk()
    checkApproveKKUB()
    checkCanClaim()

    getKKubAmount()
    getPunkAmount()
  }

  async function checkApprovePunk() {
    if (address != '') {
      const allowAmount = PunkUtil.methods.allowance(address,diamonRouter).call()
      allowAmount.then(function(data) {
        setAllowPunkAmount(data);
        // console.log("Punk : ", data)
      })

    }else 
      console.log("Not found address")
  }

  async function checkApproveKKUB() {
    if (address != '') {
      const allowAmount2 = KKUBUtil.methods.allowance(address,diamonRouter).call()
      allowAmount2.then(function(data) {
        setAllowKKUBAmount(data);
        // console.log("KKUB : ", data)
      })

    }else 
      console.log("Not found address")
  }

  async function checkCanClaim() {
    if (address != '') {
      
      const redeem = HoolUtil.methods.checkCanClaim().call(
        {
        from: address
      })
      // const redeem = HoolUtil.methods.checkCanClaim().call()
      redeem.then(function(data) {
        setCanClaim(data);
        console.log("checkIsRedeem : ", data)
      })

    }else 
      console.log("Not found address")
  }

  async function checkOpenRedeem() {
    // console.log("contract", HoolUtil);

    const open = HoolUtil.methods.checkActive().call()
    // console.log(open)
    open.then(function(data) {
      setOpenRedeem(data);
      console.log("Open to Redeem ", data)
    })
  }

  async function getKKubAmount() {
    if (address != '') {
      const ab = KKUBUtil.methods.balanceOf(address).call()
      ab.then(function(data) {
        // console.log("KKUB " ,data/1000000000000000000)
        setKKUBAmount(data/1000000000000000000)
      })
    }else {

    }
  }

  async function getPunkAmount() {
    if (address != '') {
      const ab = PunkUtil.methods.balanceOf(address).call()
      ab.then(function(data) {
        // console.log("Punk " ,data/1000000000000000000)
        setPunkAmount(data/1000000000000000000)
      })
    }else {

    }
  }

  async function approvePunk() {
    openModal()
    PunkUtil.methods.approve(diamonRouter, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").send({
      from: address,
      gas: "50000"
    }).on('transactionHash', function(hash){
      // console.log("TransactionHash", hash)
    })
    .on('receipt', function(receipt){
      console.log("receipt", receipt)
      closeModal()
      checkApprovePunk()
    })
    .on('confirmation', function(confirmationNumber, receipt){
      // console.log("confirmationNumber", receipt)
    })
    .on('error', function(error, receipt) {
      console.log("error", error, receipt)
      closeModal()
    });
  }

  async function approveKKUB() {
    openModal()
    // const r = await 
    KKUBUtil.methods.approve(diamonRouter, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").send({
      from: address,
      gas: "50000"
    }).on('transactionHash', function(hash){
      // console.log("TransactionHash", hash)
    })
    .on('receipt', function(receipt){
      // console.log("receipt", receipt)
      closeModal()
      checkApproveKKUB()
    })
    .on('confirmation', function(confirmationNumber, receipt){
      // console.log("confirmationNumber", receipt)
    })
    .on('error', function(error, receipt) {
      console.log("error", error, receipt)
      closeModal()
    });

    // console.log("After Approve" ,r);
  }
  
  async function register() {
    openModal()

    // console.log("ClaimPunk with", address)

    HoolUtil.methods.claimPunk().send({
      from: address,
      gas: "1000000"
    }).on('transactionHash', function(hash){
      // console.log("TransactionHash", hash)
    })
    .on('receipt', function(receipt){
      // console.log("receipt", receipt)
      checkCanClaim()
      closeModal()
    })
    .on('confirmation', function(confirmationNumber, receipt){
      console.log("confirmationNumber", receipt)
    })
    .on('error', function(error, receipt) {
      console.log("error", error, receipt)
      setMessage(error.message)
      // closeModal()
    });
    
  }

  function openModal() {
    setShowModal(true)
  }

  function closeModal() {
    console.log("CLOSER")
    setShowModal(false)
  }

  async function connect() {
    try {
    await window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        if (err.code === 4001) {
          // EIP-1193 userRejectedRequest error
          // If this happens, the user rejected the connection request.
          console.log('Please connect to MetaMask.');
        } else {
          console.error(err);
        }
      });

      window.ethereum.on('accountsChanged', handleAccountsChanged);


    }catch (error) {
      console.log("error ", error);
    }
  }

  async function disconnect() {
    setAddress('');
    currentAccount = null;

  }


  // window.ethereum.on('accountsChanged', handleAccountsChanged);

  async function init() {

    // const accounts = await web3.eth.getAccounts()
    // console.log(accounts)
    
    window.ethereum
    .request({ method: 'eth_accounts' })
    .then(handleAccountsChanged)
    .catch((err) => {
      console.error(err);
    });
  }


  // init();

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const mystyle = {
      color: "white",
      backgroundColor: "#141D25",
      padding: "10px",
      borderRadius: "8px",
      marginBottom: "10px"
    };


  useEffect(() => {
    checkAll();
  },[address]);


  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className="row">

            
            <div className="punk-event-image-container">
              <img src="/event/hooligan.png" width="300" className="punk-even-image"/>
            </div>

            <div className="punk-event-detail-container">
              <div className="mt-0 mb-16" data-reveal-delay="200">
                Connect Metamask to check permission for receive <span className="punk-txt">Hooligan NFT.</span>
                <div className="pl-24 align-left mt-24">
                  <div>1. &nbsp;Connect Metamask to check permission</div>
                  <div>2. Check you already have <span className="punk-txt">1 Punk Token</span> and <span className="punk-txt">0.3 KKUB</span></div>
                  <div>3. Burn Punk Token for <span className="punk-txt">Hooligan NFT</span></div>
                </div>
              </div>
            </div>

          </div>

          <div>

            {
              !openRedeem &&
              <div className="mt-32 block">
                Event is closed
              </div>
            }
            
            {openRedeem && address == ''  &&
            <div className="mt-32 block">
              <img src="/event/metamask-button.png" onClick={connect} className="btn-metamask"/>
            </div>
            }

            { openRedeem && address != '' && !canClaim &&
              <div className="mt-32 block">
                <img src="/event/wallet.png" width="24" className="img-wallet"/>
                <span className="txt-wallet-address">
                  {address}
                  &nbsp;&nbsp;
                  <button className="button button-dark-secondary button-sm" onClick={disconnect}>disconnect</button>
                </span>
                <br/>
                <b>Sorry, your address cannot join this event. </b>
              </div>
            }

            { openRedeem && address != '' && canClaim && 
              <div className="mt-24 block">

                <div className="inline-block">

                  <div style={mystyle}>
                    <img src="/event/wallet.png" width="24" className="img-wallet"/>
                    <span className="txt-wallet-address">
                      {address}
                      &nbsp;&nbsp;
                      <button className="button button-dark-secondary button-sm" onClick={disconnect}>disconnect</button>
                    </span>
                  </div>

                  { (punkAmount > 0 && kkubAmount > 0.3 ) ?
                  <button className="button button-block button-primary mt-24 button-sm" onClick={register}>
                    GET HOOLIGAN PUNK NFT NOW !!! 
                  </button>
                  : 
                    <div>
                      You need <span className="punk-txt">1 Punk</span> and <span className="punk-txt">0.3 KKUB</span>. Please check your wallet.
                    </div>
                  
                  }

                  <div className="inline-block">
                    { allowPunkAmount == 0 && 
                    <button className="button button-primary mt-4" onClick={approvePunk}>
                      APPROVE PUNK
                    </button>
                    }

                    &nbsp;&nbsp;
                    { allowKKUBAmount == 0 && 
                    <button className="button button-primary mt-4" onClick={approveKKUB}>
                      APPROVE KKUB
                    </button>
                    }
                  </div>
                  
                </div>

              </div>  
            }
            

            { showModal && 
            <div className="modal-main">
              
              <div className="modal-content">
                <div className="close" onClick={closeModal}>x</div>
                <div>
                  {message}
                </div>
              </div>
            </div>
            }

          </div>

        </div>
      </div>
    </section>
  );
}

EventBurn.propTypes = propTypes;
EventBurn.defaultProps = defaultProps;

export default EventBurn;
